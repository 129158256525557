import styles from './WelcomeSection.module.scss';
import img from '../../assets/photo.png';
import phoneFormatter from 'phone-formatter';
import clipboard from 'clipboard-copy';
import PHONE from "../../PHONE";


const WelcomeSection = () => {
    return (
        <div className={styles.wrapper}>
            <div className={styles.infoBlock}>
                <h1>Настольный теннис в Москве</h1>
                <h2>Групповые и индивидуальные тренировки<br/>с мастером спорта Павлом Перовым</h2>
                <div className={styles.infoContacts}>
                    <a className={styles.infoBlockWA} href={`https://api.whatsapp.com/send?phone=${PHONE}&text=%D0%A2%D1%80%D0%B5%D0%BD%D0%B8%D1%80%D0%BE%D0%B2%D0%BA%D0%B8%20%D0%BF%D0%BE%20%D0%BD%D0%B0%D1%81%D1%82%D0%BE%D0%BB%D1%8C%D0%BD%D0%BE%D0%BC%D1%83%20%D1%82%D0%B5%D0%BD%D0%BD%D0%B8%D1%81%D1%83!`} >
                        Написать в WhatsApp
                    </a>
                    <a className={styles.infoBlockPhone} href={`tel:${PHONE}`} onClick={() => clipboard(PHONE)}>
                        {phoneFormatter.format(PHONE, "+7 (NNN) NNN-NNNN")}
                    </a>
                    <a href="https://www.youtube.com/@TTPavelPerov78" rel="noreferrer" target="_blank" className={styles.infoBlockYoutube}>
                        Мой youtube канал
                    </a>
                </div>
            </div>
            <div className={styles.wrapperRight}>
                <div className={`${styles.infoContacts} ${styles.infoContactsImg}`}>
                    <a className={styles.infoBlockWA} href={`https://api.whatsapp.com/send?phone=${PHONE}&text=%D0%A2%D1%80%D0%B5%D0%BD%D0%B8%D1%80%D0%BE%D0%B2%D0%BA%D0%B8%20%D0%BF%D0%BE%20%D0%BD%D0%B0%D1%81%D1%82%D0%BE%D0%BB%D1%8C%D0%BD%D0%BE%D0%BC%D1%83%20%D1%82%D0%B5%D0%BD%D0%BD%D0%B8%D1%81%D1%83!`} >
                        Написать в WhatsApp
                    </a>
                    <a className={styles.infoBlockPhone} href={`tel:${PHONE}`} onClick={() => clipboard(PHONE)}>
                        {phoneFormatter.format(PHONE, "+7 (NNN) NNN-NNNN")}
                    </a>
                    <a href="https://www.youtube.com/@TTPavelPerov78" rel="noreferrer" target="_blank" className={styles.infoBlockYoutube}>
                        Мой youtube канал
                    </a>
                </div>
                <img className={styles.wrapperImage} src={img} loading="lazy" alt="Павел Перов тренер по настольному теннису в Москве"/>
            </div>
        </div>
    );
};

export default WelcomeSection;