import {FC, useState} from "react";
import PhotoAlbum from "react-photo-album";
import Lightbox from "yet-another-react-lightbox";

import "yet-another-react-lightbox/styles.css";

import galleryManifest from "./photos/manifest.json";

import styles from './GallerySection.module.scss';

/**
 * https://codesandbox.io/s/yet-another-react-lightbox-examples-9qvmif?file=/src/examples/PhotoGallery.js:100-125&initialpath=/examples/gallery
 */

const photos = galleryManifest.map(({thumb, ...photo}, index) => thumb ? ({
    src: require(`./photos/thumbnails/${thumb.name}`),
    key: `${index}`,
    width: thumb.width,
    height: thumb.height,
}) : ({
    src: require(`./photos/${photo.name}`),
    key: `${index}`,
    width: photo.width,
    height: photo.height,
}));
const slides = galleryManifest.map((photo, index) => ({
    src: require(`./photos/${photo.name}`),
    key: `${index}`,
    width: photo.width,
    height: photo.height,
}));

const GallerySection: FC<{
    filter?: string[]
}> = ({filter}) => {
    const [index, setIndex] = useState(-1);

    return (
        <div className={styles.wrapper}>
            <PhotoAlbum
                layout="columns"
                photos={photos.filter(({src}) => filter?.some(item => src.includes(item)))}
                targetRowHeight={150}
                onClick={({ index }) => setIndex(index)}
            />
            <Lightbox
                open={index >= 0}
                index={index}
                close={() => setIndex(-1)}
                slides={slides}
            />
        </div>
    );
};

export default GallerySection;