import styles from './GallerySection.module.scss';
import photo1 from './small_photo.jpg';

const AboutSection = () => (
    <div className={`${styles.wrapper} ${styles.wrapper_base}`}>
        <div className={`${styles.block} ${styles.block_image}`}>
            <img src={photo1} alt="Тренировки по настольному теннису в Москве" loading="lazy"/>
        </div>
        <div className={`${styles.wrapper} ${styles.wrapper_column}`}>
            <div className={styles.title}><h1>О тренере</h1></div>
            <div className={`${styles.wrapper} ${styles.wrapper50}`}>
                <div className={styles.block}>
                    Мастер спорта по настольному теннису. <br/>
                    Тренер-преподаватель высшей категории со стажем работы более 20 лет. <br/>
                    В 2000 году окончил ЯГПУ (ярославский государственный педагогический университет им.К.Д.Ушинского)
                </div>
                <div className={styles.block}>
                    Многократный чемпион города Ярославля, призёр Чемпионата России и международных турниров.<br/>
                    В 2000-2001 годах находился в Италии и выступал за профессиональный клуб "CUS Messina".<br/>
                    За многолетний период тренерской работы удалось воспитать множество КМС, несколько мастеров спорта,
                    а также победительницу первенства России, которая в настоящее время является членом сборной России.
                </div>
            </div>
        </div>
    </div>
);

export default AboutSection;