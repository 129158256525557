import {Helmet} from "react-helmet";
import ogImage from './table-tennis.png';
import PHONE from "../../PHONE";
import phoneFormatter from "phone-formatter";

const MetaSection = () => (
    <Helmet>
        <script type="application/ld+json">
            {JSON.stringify({
                "@context" : "https://schema.org",
                "@type" : "Person",
                "name" : "Павел Перов",
                "jobTitle": "Тренер по настольному теннису",
                "gender": "male",
                "telephone": phoneFormatter.format(PHONE, "+7 (NNN) NNN-NNNN"),
                "birthDate": "1978-25-01",
                "nationality": "Russian",
                "url" : "https://table-tennis-moscow.ru"
            })}
        </script>
        <meta property="og:title" content="Настольный теннис в Москве | Павел Перов" />
        <meta property="og:image" content={`https://table-tennis-moscow.ru${ogImage}`} />
        <meta property="og:image:type" content="image/jpeg" />
        <meta property="og:image:width" content="600" />
        <meta property="og:image:height" content="315" />
    </Helmet>
);

export default MetaSection;