import React from 'react';

import './reset.css';
import styles from './App.module.scss';

import GallerySection from "./sections/GallerySection/GallerySection";
import WelcomeSection from "./sections/WelcomeSection/WelcomeSection";
import AboutSection from "./sections/AboutSection/AboutSection";
import PriceSection from "./sections/PriceSection/PriceSection";
import MapSection from "./sections/MapSection/MapSection";
import VideoSection from "./sections/VideoSection/VideoSection";
import MetaSection from "./sections/MetaSection/MetaSection";
import ReviewSection from "./sections/ReviewSection/ReviewSection";
import TypesTraning from "./sections/TypesTraning/TypesTraning";

function App() {
  return (
    <div className={styles.wrapper}>
        <MetaSection />
        <WelcomeSection />
        <TypesTraning />
        <AboutSection />
        <GallerySection  filter={[
            '11.', '444.', 'LuCenter_8269', 'LuCenter_8274', 'LuCenter_8284', 'LuCenter_8293', 'LuCenter_8297',
            'pic4', 'picture1', 'picture2', 'yantar2'
        ]}/>
        <ReviewSection />
        <PriceSection />
        <VideoSection />
        <MapSection />
    </div>
  );
}

export default App;
