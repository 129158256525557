import styles from './VideoSection.module.scss';

const videos = [
    {
        name: 'Беру интервью у детской группы',
        youtubeId: 'nJS1gFcftLU',
    },
    {
        name: 'Фрагмент с тренировки №1',
        youtubeId: 'O44NRjZjwfE',
    },
    {
        name: 'Фрагмент с тренировки №2',
        youtubeId: '1js5mWec61A',
    },
    {
        name: 'Фрагмент с тренировки №3',
        youtubeId: 'Kvxh-v0j7nM',
    },
    {
        name: 'Igor Rubtsov - Pavel Perov. Russian Men\'s Super League',
        youtubeId: 'eYbTaYjFqPs',
    },
    {
        name: 'Андрей БАЙБУЛДИН vs Павел ПЕРОВ, Чемпионат России',
        youtubeId: 'QfnpByLYVsM',
    },
    {
        name: 'П. Корбел (Чехия) - П. Перов (Ярославль)',
        youtubeId: '8_3ndLMrCEE',
    },
    {
        name: 'Подборка красивых розыгрышей с моим участием в LIGA PRO',
        cover: `https://i3.ytimg.com/vi/1BrlL4DEwHE/hqdefault.jpg`,
        url: 'https://www.youtube.com/playlist?list=PLidcw7lHiw0w3Dpb_0U4Swf6dYPVZy1f1'
    },
];

const VideoSection = () => (
    <div className={styles.wrapper}>
        <h1>Видео с тренировок и матчей</h1>
        <div className={styles.itemWrapper}>
            {videos.map((item, index) => (
                <a key={index} rel="noreferrer" className={styles.item} target="_blank" href={!item.url ? `https://www.youtube.com/watch?v=${item.youtubeId}` : item.url}>
                    <div className={styles.itemCover} style={{
                        backgroundImage: !item.cover ? `url(https://i3.ytimg.com/vi/${item.youtubeId}/hqdefault.jpg)` : `url(${item.cover})`
                    }}>
                        <div className={styles.itemTitle}>
                            {item.name}
                        </div>
                    </div>
                </a>
            ))}
        </div>
    </div>
);

export default VideoSection;