import styles from './GallerySection.module.scss';
import GallerySection from "../GallerySection/GallerySection";

const TypesTraning = () => (
    <div className={styles.wrapper}>
        <div className={styles.title}><h1>Разновидности обучающих тренировок</h1></div>
        <div className={styles.two}>
            <div>
                <ul>
                    <li>Кардио</li>
                    <li>Силовая</li>
                    <li>Сложно-техническая</li>
                    <li>Совмещенная</li>
                    <li>Детская</li>
                    <li>Групповая</li>
                </ul>
            </div>
            <div className={styles.typesblock}>
                <div>
                    <div className={styles.title}><h2>Кардио</h2></div>
                    <div>
                        <div className={styles.video}>
                            <iframe width="100%" height="315" src="https://www.youtube.com/embed/jmjdESzsPAY?si=1wuV3CQx7TERoUCi"
                                    title="YouTube video player" frameBorder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                    referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
                            Простые упражнения на средней интенсивности
                        </div>
                    </div>
                </div>
                <div>
                    <div className={styles.title}><h2>Силовая</h2></div>
                    <div>
                        <div>
                            <div className={styles.video}>
                                <iframe width="100%" height="315"
                                        src="https://www.youtube.com/embed/WJ5_iwO6lXo?si=k3ZHTdFN1uJwyNtw"
                                        title="YouTube video player" frameBorder="0"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                        referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
                                Работа всех частей тела в движении на высокой интенсивности
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <div className={styles.title}><h2>Сложно-техническая</h2></div>
                    <div>
                        <div className={styles.video}>
                            <iframe width="100%" height="315"
                                    src="https://www.youtube.com/embed/uL3upehTffM?si=OIGPiODEuMaiTg8c"
                                    title="YouTube video player" frameBorder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                    referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe></div>
                        Упражнения повышенной сложности на средней интенсивности. Для любителей играть на счет
                    </div>
                </div>
                <div>
                    <div className={styles.title}><h2>Детская</h2></div>
                    <div>
                        <div className={styles.video}>
                            <iframe width="100%" height="315"
                                    src="https://www.youtube.com/embed/5JZDg-xTnZ4?si=p4C_8tiQBuwQzyjx"
                                    title="YouTube video player" frameBorder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                    referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
                        </div>
                        Сложность и интенсивность тренировки корректируется от возраста ребенка
                    </div>
                </div>
                <div className={styles.fullWidth}>
                    <div className={styles.title}><h2>Групповая</h2></div>
                    <div>
                        Сложность упражнений корректируется в зависимости от уровня технической подготовки игроков
                        <GallerySection filter={[
                            'image-13-01-23-08-57-2',
                            'image-13-01-23-08-59-1',
                            'image-13-01-23-08-59-2',
                            'image-13-01-23-08-59-7',
                            '20230102_213839',
                            '20221124_161124',
                            '20211227_153254',
                        ]} />
                    </div>
                </div>
                <div>
                    <div className={styles.title}><h2>Совмещенная</h2></div>
                    <div>
                        В течении тренировки меняется сложность и интенсивность упражнений
                    </div>
                </div>
            </div>
        </div>
    </div>
);

export default TypesTraning;